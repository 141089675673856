/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Gatsby Image component is used to create images dynamically from data."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "StaticImage"), " is used for ", React.createElement(_components.em, null, "static"), " image sources.  These are images that have hard-coded file paths or remote URLs."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "GatsbyImage"), " is for ", React.createElement(_components.em, null, "dynamic"), " image sources.  It pulls up images that are passed in as props."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
